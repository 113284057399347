import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/base.css'
import '@/permission'
import {
    get,
    post,
    upload,
    del,
    put,
    download,
    postFormData
} from '@/axios/fun'
import _debounce from "./mixins/index.js"
Vue.mixin(_debounce);
Vue.use(ElementUI);
Vue.prototype.$get = get
Vue.$get = get
Vue.prototype.$post = post
Vue.$post = post
Vue.prototype.$delete = del
Vue.$delete = del
Vue.prototype.$put = put
Vue.$put = put
Vue.prototype.$upload = upload
Vue.$upload = upload
Vue.prototype.$download = download
Vue.$download = download
Vue.prototype.$postFormData = postFormData
Vue.$postFormData = postFormData
// 创建一个事件总线
Vue.prototype.$bus = new Vue();
Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
